import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_HOST_NAME } from "utils/constants";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    // baseUrl: API_HOST_NAME,

    baseUrl: "https://api.staging.bitewise.co",

    // baseUrl: "http://localhost:8080",
    // baseUrl: "https://api.bitewise.co",
    // baseUrl: "https://api.bitewise-dev.co",

    // baseUrl: "http://localhost:3003",
    // baseUrl: "https://api.bitewise.co/",

    // baseUrl: "http://192.168.203.38:3003",

    // baseUrl: 'https://mocki.io/v1',
    // baseUrl: 'http://127.0.0.1:5000/',
    // baseUrl: "http://35.213.143.146/",

    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.userToken;
      const profileReference = getState().auth.userProfileReference;
      const userIPLocation = getState().auth.userIPLocation;
      // headers.set('lang', i18n.language)
      // const language = localStorage.getItem("i18nextLng")
      //   ? localStorage.getItem("i18nextLng")
      //   : "en";
      const language = "en";
      // console.log("API get language: "+ language)
      // console.log("API token: "+ token)
      headers.set("lang", `${language}`);
      headers.set("Content-Type", "application/json");
      headers.set("Accept", "application/json");
      // headers.append('Authorization', 'Basic ' + base64.encode(username + ":" +  password));
      // headers.set("Origin", "http://localhost:3000");

      if (token) {
        headers.set("Authorization", token);
      }
      if (profileReference) {
        headers.set("x-profile-reference", profileReference);
      }

      // if(userIPLocation){
      //   console.log("userIPLocation: "+ userIPLocation);
      //   console.log("userIPLocation: "+ userIPLocation.ipapi.city +" " +userIPLocation.ipapi.countryName );
      // }else {
      //   console.log("userIPLocation is null")
      // }

      if (userIPLocation && userIPLocation.ipapi && userIPLocation.ipapi.city) {
        headers.set("city", encodeURIComponent(userIPLocation.ipapi.city));
      }
      if (
        userIPLocation &&
        userIPLocation.ipapi &&
        userIPLocation.ipapi.countryName
      ) {
        if (userIPLocation.ipapi.countryName.toUpperCase() === "VIETNAM") {
          headers.set("country", "vn");
        }
        // else if (userIPLocation.ipapi.countryName.toUpperCase() === "INDIA") {
        //   headers.set("country", "in");
        // }
        else {
          // if(userIPLocation.ipapi.countryCode)
          //   headers.set("country", userIPLocation.ipapi.countryCode);
          // else
          headers.set("country", "in");
        }
      } else if (language === "vi") {
        headers.set("country", "vn");
      } else {
        headers.set("country", "in");
      }
      // headers.set("x-profile-reference", "tuyenpt.hust@gmail.com");
      return headers;
    },
  }),
  endpoints: (build) => ({
    getProfile: build.query({
      query: () => ({
        url: "/public/api/profile",
        method: "GET",
      }),
    }),
    createProfileReference: build.mutation({
      query: (profileData) => ({
        url: "/public/api/profile",
        method: "POST",
        body: profileData,
      }),
    }),
    updateHealthCondition: build.mutation({
      query: (newHealthConditions) => ({
        url: "/public/api/profile/update_health_condition",
        method: "POST",
        body: { health_conditions: newHealthConditions },
      }),
    }),
    getHealthConditionList: build.query({
      query: () => ({
        url: "/public/api/health_conditions",
        method: "GET",
      }),
    }),
    getProductDetail: build.query({
      query: (args) => {
        const { productId, searchQueryId } = args;
        var searchQueryId2;
        if (searchQueryId === undefined || searchQueryId === null)
          searchQueryId2 = 0;
        else searchQueryId2 = searchQueryId;
        return {
          url: `public/api/products/${productId}?searchqueryid=${searchQueryId2}`,
          method: "GET",
        };
      },
    }),
    getDummyTest: build.query({
      query: () => ({
        url: "/a5761be5-c9e5-4e80-add3-063cb44820de",
        method: "GET",
      }),
    }),
    getSearchSuggestions: build.query({
      query: (args) => {
        const { searchValue, page } = args;
        const encodedSearchValue = encodeURIComponent(searchValue)
          .replace(/%E2%80%99/g, "%27")
          .replace(/%E2%80%98/g, "%27")
          .replace(/%E2%80%B2/g, "%27")
          .replace(/%20%25/g, "%27")
          .replace(/%25%20/g, "%27");
        const size = 10;
        return {
          url: `public/api/suggest?query=${encodedSearchValue}&page=${page}&size=${size}`,
          method: "GET",
        };
      },
    }),
    getSearchProductCount: build.query({
      query: (args) => {
        const { searchValue } = args;
        const encodedSearchValue = encodeURIComponent(searchValue);
        return {
          url: `public/api/products/search/count?query=${encodedSearchValue}`,
          method: "GET",
        };
      },
    }),
    getSearchByBrandCount: build.query({
      query: (args) => {
        const { searchValue } = args;
        const encodedSearchValue = encodeURIComponent(searchValue);
        return {
          url: `public/api/products/searchByBrandCount?query=${encodedSearchValue}`,
          method: "GET",
        };
      },
    }),
    getSearchIngredientCount: build.query({
      query: (args) => {
        const { searchValue } = args;
        const encodedSearchValue = encodeURIComponent(searchValue);
        return {
          url: `public/api/ingredients/search/count?query=${encodedSearchValue}`,
          method: "GET",
        };
      },
    }),
    getSearchProductList: build.query({
      query: (args) => {
        const { searchValue, page, pageSize, searchQueryId } = args;
        // Send search query header, retrieve when saved in local storage
        const searchQuery = localStorage.getItem("searchQueryText");
        const encodedSearchValue = encodeURIComponent(searchValue);
        return {
          url: `public/api/products/search?query=${encodedSearchValue}&page=${page}&size=${pageSize}&searchquery=${searchQuery}&searchqueryid=${searchQueryId}`,
          method: "GET",
        };
      },
    }),
    getSearchByBrand: build.query({
      query: (args) => {
        const { searchValue, page, pageSize, searchQueryId } = args;
        // Send search query header, retrieve when saved in local storage
        // const searchQuery = localStorage.getItem("searchQueryBrandText");
        const encodedSearchValue = encodeURIComponent(searchValue);
        return {
          url: `public/api/products/searchByBrand?query=${encodedSearchValue}&page=${page}&size=${pageSize}`,
          method: "GET",
        };
      },
    }),
    getSearchIngredientList: build.query({
      query: (args) => {
        const { searchValue, page, pageSize } = args;
        const encodedSearchValue = encodeURIComponent(searchValue);
        return {
          url: `public/api/ingredients/search?query=${encodedSearchValue}&page=${page}&size=${pageSize}`,
          method: "GET",
        };
      },
    }),
    getHistoryProductList: build.query({
      query: (args) => {
        const { page, pageSize } = args;
        return {
          url: `public/api/profile/view_histories?page=${page}&size=${pageSize}`,
          method: "GET",
        };
      },
    }),
    getAllHistoryProductList: build.query({
      query: () => {
        return {
          url: `public/api/profile/view_histories`,
          method: "GET",
        };
      },
    }),
    getDetails: build.query({
      query: (args) => {
        const { searchValue, page, pageSize } = args;
        const encodedSearchValue = encodeURIComponent(searchValue);
        return {
          url: `public/api/ingredients/search?query=${encodedSearchValue}&page=${page}&size=${pageSize}`,
          method: "GET",
        };
      },
    }),
    getAlternativesList: build.query({
      query: (args) => {
        const { ids } = args;
        // console.log("ids", ids);
        return {
          url: `public/api/products/alternatives?ids=${ids}`,
          method: "GET",
        };
      },
    }),
    getSimilarsList: build.query({
      query: (args) => {
        const { ids } = args;
        return {
          url: `public/api/products/similar?ids=${ids}`,
          method: "GET",
        };
      },
    }),
    getIngredientDetail: build.query({
      query: (args) => {
        const { id } = args;
        return {
          url: `public/api/ingredients/${id}`,
          method: "GET",
        };
      },
    }),
    getSuggestionProduct: build.query({
      query: () => {
        return {
          url: `public/api/suggest_products`,
          method: "GET",
        };
      },
    }),
    getHomePageProducts: build.query({
      query: () => {
        return {
          url: `public/api/home_page_products`,
          method: "GET",
        };
      },
    }),
    getScanBarcode: build.query({
      query: (args) => {
        const { barcode } = args;
        return {
          url: `public/api/products/scan?barcode=${barcode}`,
          method: "GET",
        };
      },
    }),
    getScanBarcodeMut: build.mutation({
      query: (args) => {
        const { barcode } = args;
        return {
          url: `public/api/products/scan?barcode=${barcode}`,
          method: "GET",
        };
      },
    }),
    getNutrientDetail: build.query({
      query: (args) => {
        const { id } = args;
        return {
          url: `public/api/nutrients/${id}`,
          method: "GET",
        };
      },
    }),
    getAllBiteboardCategories: build.query({
      query: () => {
        return {
          url: `public/api/biteboard/categories`,
          method: "GET",
        };
      },
    }),
    getAllBiteboardProductsforCategory: build.query({
      query: (args) => {
        const { id, page, pageSize } = args;
        // console.log("ID, pagesize, etc" + id + "  " + page + "  " + pageSize);
        return {
          url: `public/api/biteboard/${id}?page=${page}&size=${pageSize}`,
          method: "GET",
        };
      },
    }),
    getHomePageBiteboardProductsforCategory: build.query({
      query: (args) => {
        const { id } = args;
        return {
          url: `public/api/biteboard/homepage/${id}`,
          method: "GET",
        };
      },
    }),
    getWiseGPT: build.mutation({
      query: (args) => {
        const { message } = args;
        return {
          url: `public/api/wisegpt`,
          method: "POST",
          body: { message },
        };
      },
    }),
  }),
});

// export react hook
export const {
  useGetProfileQuery,
  useGetHealthConditionListQuery,
  useGetDummyTestQuery,
  useUpdateHealthConditionMutation,
  useGetProductDetailQuery,
  useGetSearchSuggestionsQuery,
  useGetSearchProductCountQuery,
  useGetSearchIngredientCountQuery,
  useGetSearchProductListQuery,
  useGetSearchIngredientListQuery,
  useGetHistoryProductListQuery,
  useGetAllHistoryProductListQuery,
  useCreateProfileReferenceMutation,
  useGetAlternativesListQuery,
  useGetIngredientDetailQuery,
  useGetSuggestionProductQuery,
  useGetHomePageProductsQuery,
  useGetScanBarcodeQuery,
  useGetNutrientDetailQuery,
  useGetScanBarcodeMutMutation,
  useGetAllBiteboardCategoriesQuery,
  useGetAllBiteboardProductsforCategoryQuery,
  useGetHomePageBiteboardProductsforCategoryQuery,
  useGetWiseGPTMutation,
  useGetSimilarsListQuery,
  useGetSearchByBrandCountQuery,
  useGetSearchByBrandQuery
} = authApi;

import {
  Box,
  Button,
  ButtonBase,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import FlexBetweenVertical from "components/FlexBetweenVertical";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  resetPageInit,
  setProductCategoryList,
} from "features/bottomSheet/bottomSheetSlice";
import { useEffect } from "react";
import {
  useGetAllBiteboardCategoriesQuery,
  useGetHomePageBiteboardProductsforCategoryQuery,
} from "app/services/auth/authService";
import { useNavigate } from "react-router-dom";
import { debugLog, sortDataByCategory } from "utils/commonutils";
import CategoriesListHorizontalWidget from "./CategoriesListHorizontalWidget";

function sendEvent(
  eventName,
  eventCategory,
  eventAction,
  eventLabel,
  eventValue
) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: eventName,
    eventProps: {
      category: eventCategory,
      action: eventAction,
      label: eventLabel,
      value: eventValue,
    },
  });
}

export const sampleCategoriesList = [
  {
    id: "05d039f6-2293-4f75-a269-80a5464173c2",
    name: "Snacks",
  },
  {
    id: "05d039f6-2293-4f75-a269-80a5464173c2",
    name: "Breakfast",
  },
  {
    id: "05d039f6-2293-4f75-a269-80a5464173c2",
    name: "Milk",
  },
  {
    id: "05d039f6-2293-4f75-a269-80a5464173c2",
    name: "Milk",
  },
  {
    id: "05d039f6-2293-4f75-a269-80a5464173c2",
    name: "Milk",
  },
  {
    id: "05d039f6-2293-4f75-a269-80a5464173c2",
    name: "Milk",
  },
  {
    id: "05d039f6-2293-4f75-a269-80a5464173c2",
    name: "Milk",
  },
  {
    id: "05d039f6-2293-4f75-a269-80a5464173c2",
    name: "Milk",
  },
  {
    id: "05d039f6-2293-4f75-a269-80a5464173c2",
    name: "Milk",
  },
  {
    id: "05d039f6-2293-4f75-a269-80a5464173c2",
    name: "Milk",
  },
  {
    id: "05d039f6-2293-4f75-a269-80a5464173c2",
    name: "Milk",
  },
  {
    id: "05d039f6-2293-4f75-a269-80a5464173c2",
    name: "Milk",
  },
  {
    id: "05d039f6-2293-4f75-a269-80a5464173c2",
    name: "Milk",
  },
  {
    id: "05d039f6-2293-4f75-a269-80a5464173c2",
    name: "Milk",
  },
  {
    id: "05d039f6-2293-4f75-a269-80a5464173c2",
    name: "Milk",
  },
  {
    id: "05d039f6-2293-4f75-a269-80a5464173c2",
    name: "Milk",
  },
  {
    id: "05d039f6-2293-4f75-a269-80a5464173c2",
    name: "Milk",
  },
  {
    id: "05d039f6-2293-4f75-a269-80a5464173c2",
    name: "Milk",
  },
  {
    id: "05d039f6-2293-4f75-a269-80a5464173c2",
    name: "Milk",
  },
];

var sampleProducts = [
  {
    category_id: "05d039f6-2293-4f75-a269-80a5464173c2",
    category_name: "Snacks",
    products: [
      {
        id: "a5a66788-430a-4c63-b9f3-138511bb2070",
        name: "Bánh Tráng Nướng Sốt Tôm Cay 45g",
        brand: "Batrafoco",
        overall_score: 81,
        rating: "GOOD",
        main_image:
          "https://bitewise.co/cdn/images/8938529066172-t1DVkR.png?1655188065",
      },
      {
        id: "aca7b962-2c10-454c-afc6-d92d7ac9cac1",
        name: "Bánh Tráng Phô Mai ABI 80g",
        brand: "ABI SNACK",
        overall_score: 76,
        rating: "GOOD",
        main_image:
          "https://bitewise.co/cdn/images/8938540202030-atApCe.png?1692435570",
      },
      {
        id: "25fa252f-5bfd-40f5-945f-b97557dc8c1c",
        name: "Snack Que Lotus Bacon Vàng 55G",
        brand: "Lotus",
        overall_score: 76,
        rating: "GOOD",
        main_image: "https://bitewise.co/cdn/images/8850157515214-qTF6yB.png",
      },
    ],
  },
  {
    category_id: "05d039f6-2293-4f75-a269-80a5464173c2",
    category_name: "Snacks",
    products: [
      {
        id: "7ebfa34e-2b5a-422a-80e8-c9430e605be0",
        name: "Kem Hộp Vinamilk Dâu 450ML",
        brand: "Vinamilk",
        serving_size: 100.0,
        serving_unit: "g",
        overall_score: 82,
        rating: "GOOD",
        main_image: "https://bitewise.co/cdn/images/8934673291200-rEC1xB.png",
      },
      {
        id: "0cc776e1-da10-41fe-881f-01104811934d",
        name: "Kem Hộp Vinamilk Đậu Xanh 1L",
        brand: "Vinamilk",
        serving_size: 100.0,
        serving_unit: "g",
        overall_score: 82,
        rating: "GOOD",
        main_image: "https://bitewise.co/cdn/images/8934673298803-4rx1f4.png",
      },
      {
        id: "f9081f48-eb39-4f62-8f16-09fe88b6ee7a",
        name: "Kem Twin Cows Tiramisu 450ML",
        brand: "Vinamilk",
        serving_size: 100.0,
        serving_unit: "g",
        overall_score: 81,
        rating: "GOOD",
        main_image: "https://bitewise.co/cdn/images/8934673706872-pOIfS5.png",
      },
      {
        id: "e90d8b1a-e380-42de-b4b8-81477bd1338a",
        name: "Kem Vinamilk 2 Trong 1 Vị Cốm Dừa 400ML",
        brand: "Vinamilk",
        serving_size: 100.0,
        serving_unit: "g",
        overall_score: 81,
        rating: "GOOD",
        main_image: "https://bitewise.co/cdn/images/8934673600200-rNPM20.png",
      },
      {
        id: "e2dbbcb8-1132-4aca-999d-0229a9dcdf57",
        name: "Kem TH True Ice Cream Vị Dừa Tự Nhiên 180G",
        brand: "TH",
        serving_size: 100.0,
        serving_unit: "g",
        overall_score: 81,
        rating: "GOOD",
        main_image: "https://bitewise.co/cdn/images/8935217445431-OkqCUV.png",
      },
    ],
  },
];

export const sampleProductItem = {
  id: "a5a66788-430a-4c63-b9f3-138511bb2070",
  name: "Bánh Tráng Nướng Sốt Tôm Cay 45g",
  brand: "Batrafoco",
  overall_score: 81,
  rating: "GOOD",
  main_image: "https://bitewise.co/cdn/images/8935217445431-OkqCUV.png",
};

const DiscoveryWidget = ({ isHomePage = false, isSearchPage = false }) => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const { t } = useTranslation();
  const { palette } = useTheme();
  const textMain = palette.textColor.main;
  const borderGrey = palette.borderColor.grey;
  const backgrountWhite = palette.background.alt;
  const textMainHeading = palette.textColor.mainHeading;
  const textOrangeDark = palette.textColor.orangeDark;
  const navigate = useNavigate();
  const { selectedProductCategory } = useSelector((state) => state.bottomSheet);

  const dispatch = useDispatch();

  const {
    data: dataCategoryList,
    isLoading: isCategoryListLoading,
    error: categoryListError,
    isSuccess: isCategoryListSuccessful,
  } = useGetAllBiteboardCategoriesQuery();
  const {
    data: categoryProductsList,
    isLoading: isCategoryProductsListLoading,
    isFetching: isCategoryProductsListFetching,
    error: categoryProductsListError,
    isSuccess: isCategoryProductsListSuccessfull,
  } = useGetHomePageBiteboardProductsforCategoryQuery(
    { id: selectedProductCategory.id },
    {
      skip: selectedProductCategory.id === -1,
    }
  );

  useEffect(() => {
    debugLog("Fetched all categories: " + dataCategoryList);
    dispatch(
      setProductCategoryList({
        categoryList: dataCategoryList,
      })
    );
  }, [dataCategoryList]);

  function gotoProductDetailsPage(productId) {
    if (!isCategoryProductsListFetching) navigate(`/product/${productId}`);
  }

  function gotoBiteboardPage() {
    if (isHomePage)
      sendEvent(
        "navigateToBiteboard",
        "homePage",
        "clickSeelAllForCategory",
        selectedProductCategory.id
      );
    else
      sendEvent(
        "navigateToBiteboard",
        "searchPage",
        "clickSeelAllForCategory",
        selectedProductCategory.id
      );
    navigate("/biteboard");
    dispatch(resetPageInit());
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      padding={
        isHomePage && !categoryListError && !isSearchPage
          ? "3rem 0rem 3rem 2rem"
          : "0rem"
      }
      backgroundColor={backgrountWhite}
    >
      {isHomePage && isCategoryListSuccessful && !isSearchPage && (
        <Box display="flex" flexDirection="column">
          <Typography variant="h28" fontWeight="900">
            {t("discovery.discovery_widget_title")}
          </Typography>
          <Typography
            variant="h16FuzzyBubbles"
            color={textMainHeading}
            fontWeight="700"
          >
            {t("discovery.discovery_widget_subtitle")}
          </Typography>
        </Box>
      )}
      {isSearchPage && isCategoryListSuccessful && (
        <Box display="flex" flexDirection="column">
          <Typography variant="h18" color={textMainHeading} fontWeight="800">
            {t("discovery.categories")}
          </Typography>
        </Box>
      )}

      <FlexBetweenVertical
        paddingTop={isHomePage && !categoryListError ? "1rem" : "0rem"}
        sx={{
          width: "100%",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        {/* {!isHomePage && (
          <FlexBetween width="100%" pr="1.5rem" mb="1.5rem">
            <Typography
              variant="h12"
              fontWeight="600"
              color={"#B1B8C2"}
              sx={{ textTransform: "uppercase" }}
            >
              {t("discovery.discovery_widget_subtitle")}
            </Typography>

            <ButtonBase mr="0.5rem" onClick={() => gotoBiteboardPage()}>
              <Typography variant="h12" fontWeight="700" color={textOrangeDark}>
                {t("common.see_all")}
              </Typography>
            </ButtonBase>
          </FlexBetween>
        )} */}
        {dataCategoryList?.length && (
          <CategoriesListHorizontalWidget
            categoriesList={sortDataByCategory(dataCategoryList)}
            isHomePage={isHomePage}
            isSearchPage={!isHomePage}
          />
        )}
      </FlexBetweenVertical>
    </Box>
  );
};

export default DiscoveryWidget;

import { useTheme } from "@emotion/react";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import HeaderBottomSheet from "./HeaderBottomSheet";
import InfoIcon from "@mui/icons-material/Info";
import FlexBetweenVertical from "components/FlexBetweenVertical";
import { useTranslation } from "react-i18next";

const DisclaimerWidget = (props) => {
  const { palette } = useTheme();
  const backgrountWhite = palette.background.alt;
  const { t } = useTranslation();

  const icon = <InfoIcon style={{ color: "#079449" }} />;
  const { onClose } = props;
  return (
    <Box height="100%" backgroundColor={backgrountWhite}>
      <HeaderBottomSheet
        name={t("disclaimer.title")}
        icon={icon}
        close={onClose}
      />
      <FlexBetweenVertical sx={{ gap: "1rem", paddingTop: "0.25rem" }}>
        <Typography>
          {t("disclaimer.paragraph1_uppercase")}
          {t("disclaimer.paragraph1_normal")}
          {t("disclaimer.paragraph1_uppercase_part")}
          {t("disclaimer.paragraph1_normal_part")}
        </Typography>
        <Typography>{t("disclaimer.paragraph2_title")}</Typography>
        <List component="ol">
          <ListItem component="li">
            <ListItemText primary={"i) " + t("disclaimer.paragraph2_i")} />
          </ListItem>
          <ListItem component="li">
            <ListItemText primary={"ii) " + t("disclaimer.paragraph2_ii")} />
          </ListItem>
          <ListItem component="li">
            <ListItemText primary={"iii) " + t("disclaimer.paragraph2_iii")} />
          </ListItem>
        </List>
        <Typography>
          {t("disclaimer.paragraph3_uppercase")}
          {t("disclaimer.paragraph3_normal")}
        </Typography>
        <Typography>{t("disclaimer.paragraph4_normal")}</Typography>
        <Typography>{t("disclaimer.paragraph5_uppercase")}</Typography>
        <Typography>
          {t("disclaimer.paragraph6_normal")}
          {t("disclaimer.paragraph6_uppercase")}
        </Typography>
        <Typography>{t("disclaimer.paragraph7_uppercase")}</Typography>
      </FlexBetweenVertical>
    </Box>
  );
};

export default DisclaimerWidget;

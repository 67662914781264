import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import authReducer from "./state";
import { configureStore } from "@reduxjs/toolkit";
import "@fontsource/damion";
import "@fontsource/lato";
import "@fontsource/lexend-deca";
import "@fontsource/fuzzy-bubbles";
import "@fontsource/montserrat";
import { Provider } from "react-redux";
import "./i18n";
import store from "./app/store";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import reportWebVitals from "./reportWebVitals";
import { GA_ANALYTICS_MEASUREMENT_ID } from "utils/constants";
import ReactGA from "react-ga4";
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, isSupported } from "firebase/messaging";

const root = ReactDOM.createRoot(document.getElementById("root"));
const firebaseConfig = {
  apiKey: "AIzaSyC13LeqQ2aq8B_mFVaEMdy3trjSaN-ZqTg",
  authDomain: "bitewise-f4ee3.firebaseapp.com",
  projectId: "bitewise-f4ee3",
  storageBucket: "bitewise-f4ee3.appspot.com",
  messagingSenderId: "308135343035",
  appId: "1:308135343035:web:90df811aa2e2df8a9fca9e",
  measurementId: "G-Q3NK8SX0WP",
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Request notification permission function
function requestPermission() {
  Notification.requestPermission()
    .then((permission) => {
      if (permission === "granted") {
        console.log("Notification permission granted.");
      } else {
        console.log("Notification permission denied.");
      }
    })
    .catch((err) => {
      console.log("Error requesting notification permission:", err);
    });
}
if ("serviceWorker" in navigator && "PushManager" in window) {
  // Check if service worker and Push API are supported by the browser
  const messaging = getMessaging(app);
  requestPermission();
  onMessage(messaging, (payload) => {
    console.log("Received background message ", payload);
    const notificationTitle = "Background Message Title";
    const notificationOptions = {
      body: "Background Message body.",
      icon: "/firebase-logo.png",
    };
    // Customize notification display
    new Notification(notificationTitle, notificationOptions);
  });
} else {
  console.log("Service workers are not supported in this browser.");
}

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistStore(store)}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// const SendAnalytics = ()=> {
//   ReactGA.send({
//     hitType: "pageview",
//     page: window.location.pathname,
//   });
// }
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(SendAnalytics);

import { Verified } from "@mui/icons-material";
import {
  Box,
  Typography,
  Divider,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import ProductImageProductList from "components/ProductImageProductList";
import FlexBetween from "components/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProductListItemRating from "components/ProductListItemRating";
import ProductChips from "components/ProductChips";
import AlternativeProductsList from "components/AlternativeProductsList";
import FlexBetweenVertical from "components/FlexBetweenVertical";
import {
  useGetAlternativesListQuery,
  useGetSimilarsListQuery,
} from "app/services/auth/authService";
import { useTranslation } from "react-i18next";
import {
  createChipListData,
  createNutriTag,
  getTagName,
  rateCodeOrder,
} from "utils/commonutils";
import verified from "./../../assets/verify.svg";
export const chipList = [
  {
    label: "Braad",
    chipUrl: "../assets/dislike.svg",
  },
  {
    label: "Brse",
    chipUrl: "../assets/dislike.svg",
  },
  {
    label: "Bread",
    chipUrl: "../assets/dislike.svg",
  },
  {
    label: "Bread",
    chipUrl: "../assets/dislike.svg",
  },
  {
    label: "Bread",
    chipUrl: "../assets/dislike.svg",
  },
  {
    label: "Bad",
    chipUrl: "../assets/dislike.svg",
  },
  {
    label: "Brad",
    chipUrl: "../assets/dislike.svg",
  },
  {
    label: "Bad",
    chipUrl: "../assets/dislike.svg",
  },
  {
    label: "Bread",
    chipUrl: "../assets/dislike.svg",
  },
  {
    label: "Bread",
    chipUrl: "../assets/dislike.svg",
  },
];

const ProductListItemWidget = ({
  productItem = {},
  showDivider = false,
  showBoxShadow = false,
  historyPage = false,
  shadowPercent = "15%",
  searchQueryId = null,
  handeClick = () => {},
}) => {
  const { palette, typography } = useTheme();
  const navigate = useNavigate();
  const dark = palette.neutral.dark;
  const mainHeading = palette.textColor.mainHeading;
  const medium = palette.neutral.medium;
  const main = palette.neutral.main;
  const textGreyLight = palette.textColor.greyLight;
  const shapeStyles = { bgcolor: palette.neutral.main, width: 40, height: 40 };
  const rectangle = <Box component="span" sx={shapeStyles} />;
  const fontSize16 = typography.h5.fontSize;
  const bgWhite = palette.background.alt;
  const isNonMobileScreens = useMediaQuery("(min-width:425px)");

  // if (!productItem) {
  //   return null;
  // }

  // const {
  //   productImageUrl,
  //   productName,
  //   location,
  //   occupation,
  //   viewedProfile,
  //   impressions,
  //   friends,
  // } = productItem;
  const isAlternativeExist = true;

  const [isFetchAlternatives, setIsFetchAlternatives] = useState(false);
  const [isFetchSimilars, setIsFetchSimilars] = useState(false);
  const [alternativeShow, setAlternativeShow] = useState();

  const {
    data: alternativesList,
    isLoading,
    error,
  } = useGetAlternativesListQuery(
    { ids: productItem.id },
    {
      skip: isFetchAlternatives === false,
    }
  );

  const {
    data: similarsList,
    isLoading: isLoadingSimilars,
    error: errorSimilars,
  } = useGetSimilarsListQuery(
    { ids: productItem.id },
    {
      skip: isFetchSimilars === false,
    }
  );

  useEffect(() => {
    if (
      alternativesList &&
      alternativesList.length &&
      "NOT_RATED" != productItem.rating &&
      productItem.rating != "GOOD"
    ) {
      setAlternativeShow(alternativesList || []);
    }
    if (
      similarsList &&
      similarsList.length &&
      "NOT_RATED" != productItem.rating &&
      "GOOD" == productItem.rating
    ) {
      setAlternativeShow(similarsList || []);
    }
  }, [alternativesList, similarsList]);

  const [alternativeProductImageList, setAlternativeProductImageList] =
    useState();

  const [healthRisksConditions, setHealthRisksConditions] = useState(null);

  useEffect(() => {
    if (alternativeShow) {
      const imgageListAlternative = alternativeShow[0].alternative_products.map(
        (value) => value.main_image
      );

      setAlternativeProductImageList(imgageListAlternative);
    }
  }, [alternativeShow]);

  useEffect(() => {
    const health_risks_conditions = geHealthRisksConditions(
      productItem.health_risks,
      productItem.health_conditions
    );
    if (health_risks_conditions)
      setHealthRisksConditions(health_risks_conditions);

    if (
      productItem &&
      productItem.rating &&
      "NOT_RATED" != productItem.rating &&
      productItem.rating != "GOOD"
    ) {
      setIsFetchAlternatives(true);
    }
    if (
      productItem &&
      productItem.rating &&
      "NOT_RATED" != productItem.rating &&
      "GOOD" == productItem.rating
    ) {
      setIsFetchSimilars(true);
    }
  }, []);

  function geHealthRisksConditions(health_risks, health_conditions) {
    if (health_risks && health_conditions)
      return health_risks.concat(health_conditions);
    if (health_risks) return health_risks;
    return health_conditions;
  }

  const { t } = useTranslation();
  const [chipList, setChipList] = useState();

  useEffect(() => {
    if (productItem) {
      const nutriTag = createNutriTag(productItem);
      const data = createChipListData(productItem, nutriTag);
      setChipList(data);
    }
  }, [productItem]);

  return (
    <Box padding="0.5rem 0.75rem 0.5rem 0.75rem">
      <WidgetWrapper
        width="100%"
        sx={{
          boxShadow: showBoxShadow
            ? "2px 4px 10px 0px rgb(0 0 0 / " + shadowPercent + ")"
            : 0,
          position: "relative",
          borderRadius: "1rem",
          background: bgWhite,
          padding: "1rem 0.75rem 0.75rem 0.75rem",
        }}
        onClick={() => {
          if (searchQueryId >= 0) {
            navigate(`/product/${productItem.id}`, {
              state: { searchQueryId: searchQueryId },
            });
          } else {
            navigate(`/product/${productItem.id}`);
          }
          handeClick(productItem);
        }}
      >
        {/* FIRST ROW */}
        <FlexBetween
          gap="0.5rem"
          pb="0.5rem"
          sx={{ alignItems: "flex-start" }}
          // onClick={() => navigate(`/profile/${userId}`)}
        >
          <FlexBetween gap="0.75rem" sx={{ alignItems: "flex-start" }}>
            <ProductImageProductList
              imageUrl={productItem.main_image ? productItem.main_image : ""}
            />
            <FlexBetweenVertical mt="0.25rem" sx={{ alignItems: "flex-start" }}>
              <Typography
                variant="h14"
                color={mainHeading}
                fontWeight="500"
                lineHeight={"18px"}
              >
                {productItem.name ? productItem.name : "Product Name"}
              </Typography>
              <Typography
                color={textGreyLight}
                mb="0.5rem"
                variant="h13"
                fontWeight="300"
              >
                {productItem.brand ? productItem.brand : ""}
              </Typography>
            </FlexBetweenVertical>
          </FlexBetween>
          <ProductListItemRating rating={productItem.rating} ratingText="Bad" />
          {/* <ManageAccountsOutlined /> */}
        </FlexBetween>

        {showDivider && (
          <Divider style={{ background: "#EBEBF0", borderStyle: "dashed" }} />
        )}

        {/* {console.log("healthRisksConditions_arsy"+ healthRisksConditions.length)} */}
        {"NOT_RATED" != productItem.rating && chipList && (
          <ProductChips isClickableChip={false} chipsList={chipList} />
        )}

        {showDivider && (
          <Divider style={{ background: "#EBEBF0", borderStyle: "dashed" }} />
        )}

        {/* THIRD ROW */}
        {/* SECOND ROW */}
        {("NOT_RATED" !== productItem.rating ||
          alternativeProductImageList?.length > 0) && (
          <Box
            pt="0.75rem"
            display="flex"
            flexDirection={isNonMobileScreens ? "row" : "column"}
            justifyContent="space-between"
            alignItems={isNonMobileScreens ? "center" : "flex-start"}
          >
            <Box display="flex" alignItems="center" gap="0.5rem">
              <img src={verified} alt="verify" />
              {alternativeProductImageList?.length > 0 ? (
                <>
                  <Typography variant="h12" color="#7D8899" fontWeight="600">
                    {alternativeProductImageList?.length}
                  </Typography>
                  <Typography variant="h12" color="#7D8899" fontWeight="400">
                    {productItem?.rating === "GOOD"
                      ? t("alternatives.similar_title")
                      : t("alternatives.healthier_alternatives")}
                  </Typography>
                </>
              ) : (
                <Typography variant="h12" color={medium} fontWeight="400">
                  {productItem?.rating === "GOOD"
                    ? t("alternatives.similar_product_empty")
                    : t("productDetail.healthieralternative_notfound")}
                </Typography>
              )}
            </Box>
            <AlternativeProductsList
              alternativeImageList={alternativeProductImageList?.slice(0, 5)}
            />
          </Box>
        )}
      </WidgetWrapper>
    </Box>
  );
};

export default ProductListItemWidget;

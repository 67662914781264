import { Verified } from "@mui/icons-material";
import {
  Box,
  Typography,
  Divider,
  useTheme,
  Badge,
  Avatar,
  Chip,
} from "@mui/material";
import ProductImageProductList from "components/ProductImageProductList";
import FlexBetween from "components/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import ProductDetailItemRating from "components/ProductDetailItemRating";
import { ReactComponent as InfoIcon } from "../../assets/info_icon.svg";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import FlexBetweenVertical from "components/FlexBetweenVertical";
import { useEffect } from "react";
import { debugLog } from "utils/commonutils";
import verified from "./../../assets/verify.svg";
import landscapes from "./../../assets/files-landscapes.svg";
import { useNavigate } from "react-router-dom";

const ProductDetailsProductWidget = ({
  productItem = {},
  alternativeCount = -1,
  brandProductsCount = -1,
  showBoxShadow = false,
  isNonMobileScreens = true,
  isNotRatedSpecialProduct = false,
}) => {
  const { palette } = useTheme();
  const main = palette.primary.main;
  const textGreyLight = palette.textColor.greyLight;
  const textColorMain = palette.textColor.main;
  const borderGrey = palette.borderColor.grey;
  const { t } = useTranslation();
  const navigate = useNavigate();
  debugLog("isNotRatedSpecialProduct" + isNotRatedSpecialProduct);
  useEffect(() => {
    if (productItem) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "event",
        eventProps: {
          category: "rating",
          action: "view",
          label: productItem.id,
          value: productItem.rating,
        },
      });
    }
  }, [productItem]);

  return (
    <WidgetWrapper sx={{ padding: "0px" }}>
      {/* FIRST ROW */}

      <FlexBetween
        sx={{ alignItems: "flex-start", justifyContent: "flex-start" }}
        gap="1rem"
      >
        <ProductImageProductList
          imageUrl={productItem.main_image ? productItem.main_image : ""}
          size="116px"
        />
        <FlexBetweenVertical
          gap="0.25rem"
          sx={{ alignItems: "flex-start", justifyContent: "flex-start" }}
        >
          <Typography
            variant="h18"
            color={main}
            fontWeight="600"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
            }}
          >
            {productItem.name ? productItem.name : "Product Name"}
          </Typography>
          <Typography
            color={textGreyLight}
            mb="0.5rem"
            variant="h14"
            fontWeight="400"
          >
            {productItem.brand ? productItem.brand : ""}
          </Typography>

          {/* RATING WIDGET */}
          {productItem.rating && (
            <ProductDetailItemRating
              rating={productItem.rating}
              score={productItem.overall_score}
            />
          )}
          {alternativeCount > 0 && (
            <Box display="flex" alignItems="center" gap="0.25rem" mt="0.5rem">
              <img src={verified} alt="verify" />
              <FlexBetweenVertical sx={{ alignItems: "flex-start" }}>
                <Box
                  display="flex"
                  alignItems="center"
                  gap="0.25rem"
                  onClick={() => {
                    if (!isNonMobileScreens) {
                      window.scrollTo({
                        top: document.body.scrollHeight,
                        left: 0,
                        behavior: "smooth",
                      });
                    }
                  }}
                >
                  <Typography
                    variant="h12"
                    fontWeight="400"
                    color={textGreyLight}
                  >
                    {t("productDetail.healthieralternative_count_1")}
                  </Typography>
                  <Typography
                    variant="h12"
                    fontWeight="600"
                    color={textGreyLight}
                  >
                    {alternativeCount}
                  </Typography>
                  <Typography
                    variant="h12"
                    fontWeight="400"
                    color={textGreyLight}
                  >
                    {productItem?.rating === "GOOD"
                      ? t("alternatives.similar_title")
                      : t("productDetail.healthieralternative_count_2")}
                  </Typography>
                  {alternativeCount == 0 && (
                    <Typography
                      variant="h12"
                      fontWeight="400"
                      color={textGreyLight}
                    >
                      {productItem?.rating === "GOOD"
                        ? t("alternatives.similar_product_empty")
                        : t("productDetail.healthieralternative_notfound")}
                    </Typography>
                  )}
                </Box>
                <Divider
                  display="flex"
                  width="100%"
                  style={{
                    background: "#EBEBF0",
                    ml: "2px",
                    borderStyle: "dashed",
                  }}
                />
              </FlexBetweenVertical>
            </Box>
          )}

          {alternativeCount == 0 && "GOOD" === productItem.rating && (
            <Box display="flex" alignItems="center" gap="0.25rem" mt="0.75rem">
              <Verified fontSize="small" sx={{ color: "green" }} />
              <FlexBetweenVertical sx={{ alignItems: "flex-start" }}>
                <Typography
                  variant="h12"
                  fontWeight="400"
                  color={textGreyLight}
                >
                  {productItem?.rating === "GOOD"
                    ? t("alternatives.similar_product_empty")
                    : t("productDetail.healthieralternative_notfound")}
                </Typography>
                <Divider
                  display="flex"
                  width="100%"
                  style={{
                    background: "#EBEBF0",
                    ml: "2px",
                    borderStyle: "dashed",
                  }}
                />
              </FlexBetweenVertical>
            </Box>
          )}
          {brandProductsCount > 0 ? (
            <Box display="flex" alignItems="center" gap="0.25rem" mt="0.25rem">
              <img src={landscapes} alt="landscapes" />
              <FlexBetweenVertical sx={{ alignItems: "flex-start" }}>
                <Box
                  display="flex"
                  alignItems="center"
                  gap="0.25rem"
                  onClick={() => {
                    navigate(`/product-brand/${productItem.brand}`);
                    localStorage.setItem(
                      "productBrandCount",
                      brandProductsCount
                    );
                  }}
                >
                  <Typography
                    variant="h12"
                    fontWeight="400"
                    color={textGreyLight}
                  >
                    {t("productDetail.see")}
                  </Typography>
                  <Typography
                    variant="h12"
                    fontWeight="600"
                    color={textGreyLight}
                  >
                    {brandProductsCount}
                  </Typography>
                  <Typography
                    variant="h12"
                    fontWeight="400"
                    color={textGreyLight}
                  >
                    {brandProductsCount > 1
                      ? t("productDetail.products_from_this_brand")
                      : t("productDetail.product_from_this_brand")}
                  </Typography>
                </Box>
                <Divider
                  display="flex"
                  width="100%"
                  style={{
                    background: "#EBEBF0",
                    ml: "2px",
                    borderStyle: "dashed",
                  }}
                />
              </FlexBetweenVertical>
            </Box>
          ) : (
            <></>
          )}
        </FlexBetweenVertical>
      </FlexBetween>

      {/* No nutrition facts Widget/ Not Rated/ Not Rated for special cases*/}
      {(productItem.rating === "NOT_RATED" ||
        isEmpty(productItem.nutrients)) && (
        <Box
          gap="0.75rem"
          mt="0.75rem"
          pl="1rem"
          pt="1rem"
          pb="1rem"
          pr="1rem"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          sx={{ border: 1.5, borderColor: borderGrey, borderRadius: "1rem" }}
        >
          <Box width="0.833rem" height="0.833rem" mt="0.125rem">
            <InfoIcon />
          </Box>
          <Typography variant="h13" color={textColorMain} fontWeight="400">
            {productItem.rating === "NOT_RATED" && isNotRatedSpecialProduct
              ? t("productDetail.not_rated_special_products_title")
              : productItem.rating === "NOT_RATED"
              ? t("productDetail.product_not_rated")
              : t("productDetail.no_nutrition_facts")}
            {/* {"Bitewise do not have Nutrition Fact information of this product. We can only provide the feedback for the ingredients."} */}
          </Typography>
        </Box>
      )}
    </WidgetWrapper>
  );
};

export default ProductDetailsProductWidget;
